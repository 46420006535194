<dx-date-range-box
  class="date-range-box"
  startDateLabel="Début"
  endDateLabel="Fin"
  [showClearButton]="true"
  (onValueChanged)="onValueChanged($event)"
  width="275"
  [height]="height"
  [(value)]="selectedDates"

>
</dx-date-range-box>
