// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.0-10317(cdc7ea1551)-C18/09/2024-14:39:47-B18/09/2024-15:15:53' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.0-10317(cdc7ea1551)-C18/09/2024-14:39:47-B18/09/2024-15:15:53",
  branch: "production",
  latestTag: "6.0",
  revCount: "10317",
  shortHash: "cdc7ea1551",
  longHash: "cdc7ea1551ea9d8aa2b0f7ea74f552aa15a303c9",
  dateCommit: "18/09/2024-14:39:47",
  dateBuild: "18/09/2024-15:15:53",
  buildType: "Ansible",
  } ;
